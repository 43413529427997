import React, { FC } from 'react';
import { useParams } from 'react-router-dom';
import { Locale } from '../types/locale.enum';
import { translations } from '../translations';

type Props = {
    children: string;
}

const Translate: FC<Props> = ({children}) => {
    const { locale = Locale.EN } = useParams<{locale: Locale}>()
    const translation_key = children
    const fallback_locale = Locale.EN

    function getTranslation(test_locale: Locale) {
        if (Object.values(Locale).includes(test_locale)) {
            const locale_data = translations[test_locale]

            if (locale_data && Object.keys(locale_data).includes(translation_key)) {
                return locale_data[translation_key]
            }
        }

        return null
    }

    for (var test_locale of [locale, fallback_locale]) {
        const translation = getTranslation(test_locale)
        if (translation) {
            return <>{ translation }</>
        }
    }

    return <>[{ children }]</>
}

export default Translate;